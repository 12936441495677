import React, { useState, useEffect, useCallback } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useTheme, useQueryParams, useSocket, useConfig } from 'bat-components';
import { useMount } from 'react-use';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

// Pages
import Blank from './../pages/Blank/Blank';
import Awake from './../pages/Awake/Awake';
import Idle from './../pages/Idle/Idle';
import LoadingPage from './../pages/Loading/Loading';
import Interactive from './../pages/Interactive/Interactive';

import style from './app.module.scss';

export default function Main() {
	useTheme('vype');
	const location = useLocation();
	const history = useHistory();
	const { debug } = useConfig();

	const debugTotal = debug ? 6 : 1;

	const {
		locale,
		interactive,
		total = debugTotal
	} = useSelector(state => ({
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		interactive: state.content.getIn(['content', 'interactive']).toJS(),
		total: state.internal.getIn(['store', 'device_totals', 'samsung_trial_tv'])
	}));

	const { sendToServer, state: socketState, listener } = useSocket();
	const { trial_wall_variant: variation = '1' } = useQueryParams();
	const [, setAllScreensConnected] = useState(false);
	const [, setServerMessage] = useState('');
	const [connectionCount, setConnectionCount] = useState(0);
	const [, setTime] = useState('');
	const [screenState, setScreenState] = useState({ type: 'loading', payload: null });

	const goTo = useCallback(
		path => {
			if (location.pathname !== path) {
				history.push(path);
			}
		},
		[history, location.pathname]
	);

	const startInteractive = useCallback(
		({ id }) => {
			if (id) {
				const exists = interactive.find(item => item.id === id);
				let firstRun = true;

				if (exists) {
					if (locale === 'us' || locale === 'za' || locale === 'uk') {
						// if (('us', 'za', 'uk').includes(locale)) {
						// in the US, allow other interactives to interrupr the current one
						if (location.pathname !== `/interactive/${id}`) {
							history.push(`/interactive/${id}`);
						}
					} else {
						if (!location.pathname.includes('interactive/')) {
							history.push(`/interactive/${id}`);
						}
					}
				} else {
					if (!location.pathname.includes('awake/')) {
						if (firstRun) {
							firstRun = false;
							sendToServer('endInteractive', {
								kioskID: variation
							});
							history.push('/awake');
						}
						history.push('/awake');
					}
				}
			}
		},
		// eslint-disable-next-line
		[locale, interactive, history, location.pathname]
	);

	useEffect(() => {
		switch (screenState.type) {
			case 'awake':
				goTo('/awake');
				break;
			case 'interactive':
				startInteractive(screenState.payload);
				break;
			case 'idle':
				goTo('/idle');
				break;
			case 'blank':
				goTo('/blank');
				break;
			case 'loading':
			default:
				goTo('/');
				break;
		}
		// eslint-disable-next-line
	}, [goTo, screenState.payload, screenState.type, startInteractive]);

	useMount(() => {
		const payload = {
			variation: variation,
			total: total,
			connectionCount: connectionCount
		};

		listener('handleSaveContent', data => {
			console.log(data);
			console.log('handleSaveContent received');
			// console.log('data', data)
			// setLocalData(data);
			sendToServer('joinedRoom', payload);
		});

		listener('handleAppRest', data => {
			window.location.reload();
		});

		setTime(new Date().toLocaleTimeString());

		listener('allScreensConnected', data => {
			setAllScreensConnected(data);
		});

		listener('handleJoinedRoom', data => {
			const { count, time } = data;
			setConnectionCount(count);
			setServerMessage(time);
		});

		listener('handleLeftRoom', data => {
			setConnectionCount(data);
		});

		listener('screenState', response => {
			if (response) {
				const screen = response[Number(variation) - 1];
				console.log(screen);
				if (screen) {
					setScreenState(screen);
				}
			}
		});
	});

	useEffect(() => {
		console.log(socketState);
		if (socketState === 'disconnected') {
			sendToServer('leftRoom', true);
			if (screenState.type !== 'blank') {
				setScreenState({ type: 'blank', payload: null });
			}
		}

		// if (socketState === 'disconnected') {
		// 	sendToServer('leftRoom', true);
		// 	// setScreenState({ type: 'idle', payload: null });
		// }

		if (socketState === 'joinedRoom') {
			const payload = {
				variation: variation,
				total: total,
				connectionCount: connectionCount
			};
			sendToServer('joinedRoom', payload);
			if (screenState.type !== 'blank') {
				setScreenState({ type: 'blank', payload: null });
			}
		}

		// eslint-disable-next-line
	}, [socketState, connectionCount]);

	return (
		<div className={clsx(style.pageWrapper)}>
			<Route path="/blank" exact component={Blank} />
			<Route path="/idle" component={Idle} />
			<Route path="/awake" exact component={Awake} />
			<Route path="/interactive/:id" component={Interactive} />
			<Route path="/" exact component={LoadingPage} />
		</div>
	);
}
