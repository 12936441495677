import { Map, fromJS } from 'immutable';

export const content = {
	state: Map({}),
	reducers: {
		setInitialState(state, payload) {
			return state.setIn(['content'], fromJS(payload));
		}
	},
	effects: {}
};
