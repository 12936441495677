import React, { useEffect, useRef } from 'react';
import { useTimeoutFn } from 'react-use';
import clsx from 'clsx';

import style from './slides.module.scss';

//////////////////////////////////////////////////////
// Create hook call callback after a certain timeout,
// but also to prevent that happening if playState is paused
// playState being paused is use mostly by the 'ad' slide
// in Welcome Screen
//////////////////////////////////////////////////////
function useDuration(callback, duration, videoState, slide) {
	// The variables are in the context wrapping the MediaSlider
	// so they don't have to be passed though layers of components

	const [, cancel, reset] = useTimeoutFn(() => {
		callback();
    }, duration);
    

	useEffect(() => {
		switch (videoState) {
			case 'play':
				reset();
				break;

			case 'pause':
				cancel();
				break;

			default:
				break;
		}
		// eslint-disable-next-line
	}, [videoState, slide]);

// 	const timer = setTimeout(() => { 
// 		callback(); 
// 	}, duration);

// 	useEffect(() => {
// 		console.log('useEffect fired', videoState, slide)
// 		switch (videoState) {
// 			case 'playing':
// 				clearTimeout(timer)
// 				break;

// 			case 'paused':
// 				break;

// 			default:
// 				break;
// 		}
// 	}, [slide, videoState]);
}


function Image({ slide, onEnd, mediaVariation, mediaTotal, onContentChange, baseTimeout }) {
	// Images just have a simple duration
	// Merged Images are default for multi-image. They take position the background image based on variation and total

	useDuration(() => {
		onEnd();
    }, slide.duration || baseTimeout, 'play', slide);
    

	useEffect(() => {
		onContentChange(slide.duration);
		// eslint-disable-next-line
	}, [])

	const multiImageClass = `image_${mediaVariation}_${mediaTotal}`;
	const isMultiImage = typeof mediaVariation !== 'undefined' && typeof mediaTotal !== 'undefined';

	return (
		<div
			className={clsx(style.image, {
				[style[multiImageClass]]: isMultiImage
			})}
			style={{
				backgroundImage: `url(${slide.content})`
			}}
		/>
	);
}

function UnmergedImage({ slide, onEnd, mediaVariation, mediaTotal }) {
	// Images just have a simple duration
	const image = slide.content[Number(mediaVariation) - 1];

	useDuration(() => {
		onEnd();
	}, slide.duration);

	return (
		<div
			className={clsx(style.image, {})}
			style={{
				backgroundImage: `url(${image})`
			}}
		/>
	);
}

function Video({ slide, onEnd, mediaVariation, mediaTotal, videoState, onCanPlay, onContentChange, onPlayingVideo, kioskSpan }) {
	// Videos call on end after the length of the video has elapsed
	// _NOT_ when the video element's onend event is fired.	
	const onMetadata = e => {
		onContentChange({
			duration: e.target.duration,
			hash: Math.random()
		})
	};

	const handleVideo = useRef();
	const { current } = handleVideo;

	if(current && videoState === 'play' && kioskSpan > 1) {
		const playPromise = current.play();
		if (playPromise !== undefined) {
				playPromise.then(_ => {
					// Automatic playback started!
					// Show playing UI.
					onPlayingVideo()
				})
				.catch(error => {
					// Auto-play was prevented
					// Show paused UI.
					console.log('VideoPlayBackError - Play', error)
					setTimeout(() => {
						onEnd()
					}, 0)
				});
		}
	}

	if(current && videoState === 'pause' && kioskSpan > 1) {
		const pausePromise = current.play();
		if (pausePromise !== undefined) {
			pausePromise.then(_ => {
				// Automatic playback started!
				// Show playing UI.
				current.pause();
				current.currentTime = 0;
			})
			.catch(error => {
				// Auto-play was prevented
				// Show paused UI.
				console.log('VideoPlayBackError - Pause', error)
			});
	}

	
	}

	// useDuration(() => {
	// 	onEnd();
	// }, length + 3 * 1000, videoState, slide);

	return (
		<video
			ref={handleVideo}
			onLoadedMetadata={onMetadata}
			onCanPlayThrough={onCanPlay}
			onEnded={onEnd}
			autoPlay={kioskSpan === 1}
			muted
			src={slide.content}
			className={clsx(style.video, {
				[style['video_' + mediaVariation + '_' + mediaTotal]]:
					typeof mediaVariation !== 'undefined' && typeof mediaTotal !== 'undefined'
			})}
		></video>
	);
}

function ForeignComponent({ slide, onEnd, customComponents }) {
	// ForeignComponent only cares about injected components from "customComponents"
	// Used mostly in Welcome Screen
	// const Component = customComponents[slide.type];

	useDuration(() => {
		onEnd();
	}, slide.duration);

	return <></>;
}

//////////////////////////////////////////////////////
// Main entry point, gets you the right component by type, otherwise
// assumes that you're using a ForeignComponent
//////////////////////////////////////////////////////
export function getComponent(type) {
	switch (type) {
		case 'multi-image':
		case 'image':
			return Image;

		case 'multi-unmerged':
			return UnmergedImage;

		case 'multi-video':
		case 'video':
			return Video;

		default:
			return ForeignComponent;
	}
}
