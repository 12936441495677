import React, { useRef, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import clsx from 'clsx';
import style from './slides.module.scss';

//////////////////////////////////////////////////////
// Create hook call callback after a certain timeout,
// but also to prevent that happening if playState is paused
// playState being paused is use mostly by the 'ad' slide
// in Welcome Screen
//////////////////////////////////////////////////////
function useDuration(callback, duration) {
	// The variables are in the context wrapping the MediaSlider
	// so they don't have to be passed though layers of components

	// const { playState, baseTimeout } = useContext(PlayingContext);

	useTimeoutFn(() => {
		callback();
	}, duration);
}

function Image({ slide, onEnd, duration}) {
	// Images just have a simple duration
	useDuration(() => {
		onEnd();
	}, duration);

	return (
		<div className={clsx(style.imageSlide)}>
			<img className={clsx(style.image)} src={slide.url} alt="" />
		</div>
	);
}

function Video({ slide, onEnd, mediaVariation, mediaTotal, duration, onCanPlay, videoStatus, onVideoEnd, latency, loop, timeStamp, currentTimeStamp}) {

	useDuration(() => {
		onEnd();
	}, duration);

	const kioskSpan = slide?.kioskSpan;
	const videoMaster = slide?.videoMaster;
	const placeHolder = slide?.fallbackImage;
	const [isLoaded, setLoaded] = useState(false)

	const handleVideo = useRef();
	const { current } = handleVideo;

	if(current && videoStatus === 'play' && kioskSpan) {
		const playPromise = current.play();
		if (playPromise !== undefined) {
			playPromise.then(_ => {
				// Automatic playback started!
				// Show playing UI.
			})
			.catch(error => {
				// Auto-play was prevented
				// Show paused UI.
				console.log('VideoPlayBackError - Play', error)
			});
		}

		if(videoMaster) {
			if(current.currentTime % 2 === 0) {
				// currentTimeStamp(current.currentTime)
			}
		}
		if(!videoMaster) {
			if (timeStamp) {
				current.currentTime = timeStamp - latency;
			}
		}
	}


	return (
		<>
		<div className={clsx(style.slideVideo)}
		>
			<div className={clsx(style.slideVideoWrapper)}>
				{loop === 'true' ?
				<>
				{placeHolder && <img src={placeHolder} className={isLoaded ? clsx(style.slideVideoPlaceholder) : ''} alt="" />}
				<video
					ref={handleVideo}
					onCanPlayThrough={onCanPlay}
					onEnded={onVideoEnd}
					onPlay={() => setLoaded(true)}
					autoPlay={kioskSpan === undefined}
					muted={true}
					loop={true}
					src={slide.content}
					className={clsx(
						style.video, {
						[style['video_' + mediaVariation + '_' + mediaTotal]]:
							typeof mediaVariation !== 'undefined' && typeof mediaTotal !== 'undefined'
						
							
					})}
				></video>
				</>
				:
				<>
				{placeHolder && <img src={placeHolder} className={isLoaded ? clsx(style.slideVideoPlaceholder) : ''} alt="" />}
				<video
					ref={handleVideo}
					onCanPlayThrough={onCanPlay}
					onEnded={onVideoEnd}
					onPlay={() => setLoaded(true)}
					autoPlay={kioskSpan === undefined}
					muted={true}
					src={slide.content}
					className={clsx(
						style.video, {
						[style['video_' + mediaVariation + '_' + mediaTotal]]:
							typeof mediaVariation !== 'undefined' && typeof mediaTotal !== 'undefined'
					})}
				></video>
				</>
				}
			</div>
		</div>
		
		</>
	);
}

function ForeignComponent({ slide, onEnd, customComponents }) {
	// ForeignComponent only cares about injected components from "customComponents"
	// Used mostly in Welcome Screen
	const Component = customComponents[slide.type];

	useDuration(() => {
		onEnd();
	}, slide.duration);

	return <Component content={slide.content}></Component>;
}

//////////////////////////////////////////////////////
// Main entry point, gets you the right component by type, otherwise
// assumes that you're using a ForeignComponent
//////////////////////////////////////////////////////
export function getSlideComponent(type) {
	switch (type) {
		case 'multi-image':
		case 'image':
			return Image;

		case 'multi-video':
		case 'video':
			return Video;

		default:
			return ForeignComponent;
	}
}
