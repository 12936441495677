const selector = state => {
	return {
		locale: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		idle: state.content.getIn(['content', 'idle']).toJS(),
		awake: state.content.getIn(['content', 'awake']).toJS(),
		interactive: state.content.getIn(['content', 'interactive']).toJS(),
	};
};

export default selector;
