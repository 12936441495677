import React, { useState, useEffect } from 'react';
import { useSocket } from 'bat-components';
import { useSelector } from 'react-redux';
import { MultiSlider } from 'components/MultiSlider/MultiSlider';
import { useMount, useUnmount } from 'react-use';
// import { calculateLatency } from './../../../utils/helper';

import multiSelector from './Idle.selector';

const Idle = () =>  {
	const { slides } = useSelector(multiSelector);
	const { sendToServer, listener } = useSocket();
	const [onIdle, setOnIdle] = useState(false);
	// const [latency, setLatency] = useState(0);
	const [videoRequestReceived, setVideoRequestReceived] = useState(false);
	const [isMounted, setIsMounted] = useState(false);
	const [slideType, setSlideType] = useState('');
	const [videoStatus, setVideoStatus] = useState('');
	const [timeStamp, setTimeStamp] = useState(0);
	const [fallback, setStartFallback] = useState(false);
	const { total } = useSelector(state => ({
		total: state.internal.getIn(['store', 'device_totals', 'samsung_trial_tv'])
	}));

	


	useMount(() => {
		// var d = new Date();
		// var t = d.getTime();
		if(isMounted) {
			setOnIdle(true)
			// sendToServer('syncLatencyCheck', t)
			// listener('handleLatency', (response) => {
			// 	setLatency(calculateLatency(response))
			// 	setLatency(response);
				
			// });
		}

		if(fallback) {
			sendToServer('fallbackReload');
		}

	});

	const currentTimeStamp = (value) => {
		if(isMounted && slideType === 'video') {
			sendToServer('videoCurrentTime', {
				"time": value
			});
		}
	}

	listener('handleVideoPlayback', (response) => {
		if(!videoRequestReceived) {
			setVideoRequestReceived(true);
			if(response && videoStatus !== response) {
				setVideoStatus('play')
			}
		}
	});

	listener('keepInSync', (response) => {
		if(response && slideType === 'video') {
			const { time } = response;
			setTimeStamp(time)
		}
	});

	useUnmount(() => {
		setOnIdle(false)
	});

	const onPlayEnd = () => {
		if(isMounted) {
			sendToServer('pingIdle', {
				"total": total
			});
		}
	}

	const onSlideType = (value) => {
		if(value === 'video') {
			setVideoStatus('paused');
		}
		setSlideType(value)
	}

	const onVideoPlayStart = () => {
		setVideoStatus('videoIsLoaded');
		if(slideType?.kioskSpan > 1 && slideType?.videoMaster) { 
			setStartFallback(true);
			sendToServer('playVideo', {
				videoPlay: true,
				kioskSpan: slideType.kioskSpan
			});
		}
	}

	const onVideoEnd = () => {
		if(slideType?.kioskSpan > 1) { 
			setVideoStatus('ended');
			setVideoRequestReceived(false);
		}
	}

	useEffect(() => {
		setIsMounted(true)
		return () => setIsMounted(false)
	}, [])


	  if (!isMounted) return (
		<>
		</>
	)
	

	return (
		<MultiSlider 
			route={'idle'}
			onPlaythroughEnd={onPlayEnd}
			media={slides} 
			latency={0}
			mountedAndPlay={onIdle}
			onSlideType={onSlideType}
			onVideoPlayStart={onVideoPlayStart}
			videoStatus={videoStatus}
			timeStamp={timeStamp}
			currentTimeStamp={currentTimeStamp}
			onVideoEnd={onVideoEnd}
			numberOfScreenInSync={0}
			loop={false}
		></MultiSlider>
	);
}

export default Idle;
