import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import style from './blank.module.scss';

import selector from './Blank.selector';

// Theme Images
import velo from 'assets/images/loader/blank_velo.jpg';
import vuse from 'assets/images/loader/blank_vuse.jpg';
import rsa_vue from 'assets/images/loader/rsa_blank_vuse.jpg'
import uk from 'assets/images/loader/load_screen.jpg';


const Blank = () => {
	const { locale, identity } = useSelector(selector);
	let localImage = '';

	

	if(locale === 'za') {
		localImage = rsa_vue;
	} else {
		if(identity) {
			if(locale === 'uk') {
				localImage = uk;
			} else {
				localImage = identity === 'vuse' ? vuse : velo;
			}
		} 
	}


	return (
		<div className={clsx(style[`c-blank`])}>
			<div className={clsx(style[`c-blank__content`])}>
				{identity && <img className={clsx(style[`c-blank__content-img`])} src={localImage} alt="content is syncronisng" />}
			</div>
		</div>
	);
}

export default Blank

