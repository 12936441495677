import React, { useState, useEffect } from 'react';
// import { Layout, MediaSlider, useSocket, useQueryParams } from 'bat-components';
import { useSocket, useQueryParams } from 'bat-components';
import { MediaSlider } from './../../InteractiveSlider/MediaSlider';
import { useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';

import selector from './Interactive.selector';

const Interactive = ({ match }) => {
	const { sendToServer, listener } = useSocket();
	const [, sethide] = useState(false);
	const [isMounted, setIsMounted] = useState(false);
	const [onInteractive, setOnInteractive] = useState(false);
	const [kioskSpan, setKioskSpan] = useState(1);
	const { trial_wall_variant: screenVariation = '1' } = useQueryParams();

	const { id } = match.params;

	const { media } = useSelector(selector);

	const interactiveContent = media.find(item => item.id === id);
	const screenContent = interactiveContent.screens[Number(screenVariation) - 1];
	const timeoutCallback = 10000;
	const videoPlay = 'pause';

	useMount(() => {
		if(isMounted) {
			setOnInteractive(true)
		}

		listener('handleInteractive', (response) => {
			const { kioskData } = response
			setKioskSpan(kioskData)
		});

	});
	

	useUnmount(() => {
		setOnInteractive(false)
	});


	const playEnd = () => {
			if(isMounted) {	
				sethide(true);
				sendToServer('endInteractive', {
					kioskID: screenVariation,
					kioskSpan: kioskSpan,
				});
			}
	};
	


	const onCanPlay = () => {

	}

	const onSlideChange = (time) => {
		
	}

	const onPlayingVideo = () => {
		
	}


	useEffect(() => {
		setIsMounted(true)
		return () => setIsMounted(false)
	  }, [])


	if (!isMounted) return (
		<>
		</>
	)

	

	return (
		<MediaSlider
			media={[screenContent]}
			onPlayEnd={playEnd}
			playthrough={'once'}
			baseTimeout={timeoutCallback}
			mountedAndPlay={onInteractive}
			onCanPlay={onCanPlay}
			onContentChange={onSlideChange}
			videoState={videoPlay}
			onPlayingVideo={onPlayingVideo}
			kioskSpan={kioskSpan}
		></MediaSlider>
	);
}

export default Interactive
