import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { getComponent } from './Slides';

import style from './mediaslider.module.scss';


export function MediaSlider({
	media = [],
	className,
	playthrough = 'loop',
	onPlayEnd,
	onSlideChange,
	baseTimeout = 10000,
	customComponents,
	playState = 'playing',
	onContentChange,
	onCanPlay,
	videoState,
	onPlayingVideo,
	playTime,
	kioskSpan
}) {
	const [slideOrig, setSlide] = useState(0);
	const slide = slideOrig % media.length;
	const currentSlide = media[slide];

	const onSlideEnd = () => {
		switch (playthrough) {
			case 'loop':
				// Loops exclusively over the length of the array, so doesn't
				// loop further if the array length == 1
				setSlide(value => (value + 1) % media.length);
				break;

			case 'force':
				// Keeps pushing to slideOrig so that single item arrays will loop
				// by forcing key updates in react
				setSlide(value => value + 1);
				break;

			case 'once':
				// Go to the end of the array then stop with an event
				if (slideOrig === media.length - 1) {
					if (onPlayEnd) onPlayEnd(slide);

					setSlide(slideOrig);
				} else {
					setSlide(slideOrig + 1);
				}

				break;

			default:
				break;
		}
	};

	useEffect(() => {
		if (onSlideChange) onSlideChange(currentSlide)
		// onContentChange()
		// eslint-disable-next-line
	}, [slide]);

	if (media.length) {
		const Component = getComponent(currentSlide.type);

		return (
			<div className={clsx(style.slider, className)}>
				<Component
					onPlayingVideo={onPlayingVideo}
					onContentChange={onContentChange}
					onEnd={onSlideEnd}
					slide={currentSlide}
					customComponents={customComponents}
					onCanPlay={onCanPlay}
					videoState={videoState}
					playTime={playTime}
					kioskSpan={kioskSpan}
					baseTimeout={baseTimeout}
				></Component>
			</div>
		);
	}

	return null;
}

MediaSlider.propTypes = {
	media: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
			duration: PropTypes.number
		})
	),
	playthrough: PropTypes.oneOf(['loop', 'force', 'once']),
	className: PropTypes.string,
	timeOut: PropTypes.number,
	variantMapping: PropTypes.object,
	customVariants: PropTypes.object,
	forceSlide: PropTypes.number
};
