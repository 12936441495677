import React from 'react';
import { useSocket } from 'bat-components';
import { useSelector } from 'react-redux';
import selector from './Loading.selector';
import style from './loading.module.scss';


const LoadingPage = () => {
	const { sendToServer } = useSocket();
	var contentCount = 0;
	const dots = Array(8).fill(0);


	function Video(src, append) {
		var v = document.createElement("video");
		if (src !== "") {
			v.src = src;
		}
		if (append === true) {
			document.body.appendChild(v);
		}
		return v;
	}


	const { locale, idle, awake, interactive } = useSelector(selector);

	if(locale === 'za') {
		setTimeout(() => {


			if (idle.length > 1) {
				idle.forEach((entity, i) => {
					const { content } = entity;
					content.forEach((data) => {
						const { url, type, content:videoContent } = data;
						if(type === 'image') {
							const newImage = new Image();
							if(url) {
								newImage.src = url;
								window[url] = newImage;
							}
						}
						if(type === 'video') {
							new Video(videoContent, false);
						}
					})
					if(idle.length === i + 1) {
						contentCount = contentCount + 1
					}
				});
			} else {
				contentCount = contentCount + 1
			}

			if (awake.length > 1) {
				awake.forEach((entity, i) => {
					const { content } = entity;
					content.forEach((data) => {
						const { url, type, content:videoContent  } = data;
						if(type === 'image') {
							const newImage = new Image();
							if(url) {
								newImage.src = url;
								window[url] = newImage;
							}
						}
						if(type === 'video') {
							new Video(videoContent, false);
						}
					})
					if(awake.length === i + 1) {
						contentCount = contentCount + 1
					}
				});
			} else {
				contentCount = contentCount + 1
			}

			if (interactive.length > 1) {
				interactive.forEach((entity, i) => {
					const { screens } = entity;
					screens.forEach((data) => {
						const { url, type, content:videoContent  } = data;
						if(type === 'image') {
							const newImage = new Image();
							if(url) {
								newImage.src = url;
								window[url] = newImage;
							}
						}
						if(type === 'video') {
							new Video(videoContent, false);
						}
					})
					if(interactive.length === i + 1) {
						contentCount = contentCount + 1
					}
				});
			} else {
				contentCount = contentCount + 1
			}

			if(contentCount === 3) {
				sendToServer('savedContent',{loaded: true});
			}

		}, 10000);
	} else {
		sendToServer('savedContent', {loaded: true});
	}


	return (
		<div>
			<div className={style.loadingParent}>
				<div className={style.loading}>
					{dots.map((_, i) => (
						<div key={i}></div>
					))}
				</div>
			</div>
		</div>
	);
}

export default LoadingPage;

