import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useQueryParams } from 'bat-components';
import selector from './MultiSlider.selector';
import { getSlideComponent } from './Slides';
import { Carousel } from '@camfolio/react-responsive-carousel';
import '@camfolio/react-responsive-carousel/lib/styles/carousel.css';
import style from './multi-slider.module.scss';

/////////////////////////////////////////////
// A simpler / more complicated version of the MediaSlider just
// for Multi Screen
/////////////////////////////////////////////
export function MultiSlider({
	media = [],
	latency,
	className,
	mountedAndPlay,
	onPlaythroughEnd,
	kioskSpan,
	onSlideType,
	onVideoPlayStart,
	onVideoEnd,
	videoStatus,
	numberOfScreenInSync,
	timeStamp,
	currentTimeStamp,
	loop,
	deviceType = 'samsung_trial_tv',
	route
}) {
	const { total } = useSelector(state => ({
		total: state.internal.getIn(['store', 'device_totals', deviceType])
	}));
	const { transitions: useTransitions = true } = useSelector(selector);
	const { trial_wall_variant: variation = '1' } = useQueryParams(); // need total and variation to calculate position

	const [interval, setIntervalTime] = useState(media[0].duration); //initial state here represents the interval for first image.
	let timeoutId;

	const [groupIndex, setGroupIndex] = useState(0);
	const [end, setEnd] = useState(false);
	const groupIndexMod = groupIndex % media.length;
	const group = media[groupIndexMod];
	const [isMounted, setIsMounted] = useState(false);

	const onSlideEnd = () => {
		if (media.length > 1) {
			if (groupIndexMod === media.length - 1) {
				setEnd(!end);
				if (onPlaythroughEnd) onPlaythroughEnd();
			}
			setGroupIndex(value => value + 1);
		}
		if (media.length === 1) {
			if (onPlaythroughEnd) onPlaythroughEnd();
		}
	};

	const onChange = (index, item) => {
		setIntervalTime(item.props['data-interval']);
		onSlideType(item.props.children.props);

		if (route === 'idle') {
			if (index === media.length - 1) {
				timeoutId = setTimeout(() => {
					if (onPlaythroughEnd) onPlaythroughEnd();
				}, item.props['data-interval'] - 200);
			}
		}
	};

	useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

	useEffect(() => {
		if (useTransitions) {
			clearTimeout(timeoutId);
		}
		// eslint-disable-next-line
	}, [route]);

	if (media.length && useTransitions) {
		return (
			<div className={clsx(style.slider, className)}>
				<Carousel
					onChange={onChange}
					autoPlay
					interval={interval}
					infiniteLoop={loop}
					showThumbs={false}
					swipeable={false}
					animationHandler={'fade'}
					showArrows={false}
					showIndicators={false}
				>
					{media.map((item, key) => {
						const screenIndex = Number(variation);
						const slide = item.content[[Number(screenIndex - 1)]];
						const fallbackImage = slide?.fallbackImage !== '' ? slide?.fallbackImage : false;
						const shouldLoop = slide?.loop ? 'true' : 'false';
						const duration = item.duration;
						const Component = getSlideComponent(slide.type);

						if (key === 0) {
							onSlideType(slide);
						}

						return (
							<div className={`slide-${key}`} key={key} data-interval={duration}>
								<Component
									onEnd={onSlideEnd}
									latency={latency}
									screenIndex={screenIndex - 1}
									slide={slide}
									mediaVariation={variation}
									mediaTotal={total}
									duration={duration}
									posterImage={fallbackImage}
									mountedAndPlay={mountedAndPlay}
									kioskSpan={kioskSpan}
									onCanPlay={onVideoPlayStart}
									videoStatus={videoStatus}
									numberOfScreenInSync={numberOfScreenInSync}
									onVideoEnd={onVideoEnd}
									loop={shouldLoop}
									timeStamp={timeStamp}
									currentTimeStamp={currentTimeStamp}
								></Component>
							</div>
						);
					})}
				</Carousel>
			</div>
		);
	}

	if (media.length && !useTransitions) {
		const screenIndex = Number(variation);
		const slide = group.content[[Number(screenIndex) - 1]];

		const fallbackImage = slide.fallbackImage !== '' ? slide.fallbackImage : false;

		const shouldLoop = slide?.loop ? 'true' : 'false';

		const Component = getSlideComponent(slide.type);

		onSlideType(slide);

		if (!isMounted && !mountedAndPlay) return <></>;

		return (
			<div className={clsx(style.slider, className)} key={groupIndex}>
				{!end && (
					<Component
						onEnd={onSlideEnd}
						latency={latency}
						screenIndex={screenIndex - 1}
						slide={slide}
						mediaVariation={variation}
						mediaTotal={total}
						duration={group.duration}
						posterImage={fallbackImage}
						mountedAndPlay={mountedAndPlay}
						kioskSpan={kioskSpan}
						onCanPlay={onVideoPlayStart}
						videoStatus={videoStatus}
						numberOfScreenInSync={numberOfScreenInSync}
						onVideoEnd={onVideoEnd}
						loop={shouldLoop}
						timeStamp={timeStamp}
						currentTimeStamp={currentTimeStamp}
					></Component>
				)}
			</div>
		);
	}

	return null;
}

MultiSlider.propTypes = {
	media: PropTypes.arrayOf(
		PropTypes.shape({
			content: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.object,
				PropTypes.array,
				PropTypes.element
			]),
			duration: PropTypes.number
		})
	),
	className: PropTypes.string
};
