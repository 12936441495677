import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MultiSlider } from 'components/MultiSlider/MultiSlider';
import { useMount, useUnmount } from 'react-use';
import selector from './Awake.selector';

const Awake = () => {
	const { media } = useSelector(selector);
	const [onAwake, setOnAwake] = useState(false);
	const [isMounted, setIsMounted] = useState(false);
	const [, setSlideType] = useState('');

	useMount(() => {
		if (isMounted) {
			setOnAwake(true);
		}
	});

	useUnmount(() => {
		setOnAwake(false);
	});

	useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

	if (!isMounted) return <></>;

	const onSlideType = value => {
		setSlideType(value);
	};

	return (
		<MultiSlider
			route={'awake'}
			onSlideType={onSlideType}
			media={media}
			latency={0}
			mountedAndPlay={onAwake}
			loop={false}
		></MultiSlider>
	);
};

export default Awake;
